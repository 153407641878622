/* eslint-disable @typescript-eslint/no-floating-promises */
import { GrowthBook } from '@growthbook/growthbook-react'
import { pageEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { getConfig } from '@configs/evnConfig'

const growthBook = env =>
  new GrowthBook({
    apiHost: getConfig(env)?.growthBookConfig?.host,
    clientKey: getConfig(env)?.growthBookConfig?.key,
    enableDevMode: env !== 'prod',
    trackingCallback: (experiment, result) => {
      pageEvent(`ab_testing_${experiment.key}`, {
        experiment_id: experiment.key,
        variation_id: result.key,
      })
    },
  })

export default growthBook
