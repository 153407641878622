'use client'

import PrimaryButton from '@components/Home/PrimaryButton/Button'
import DownloadAppCta from '@components/HomePage/DownloadAppModal/downloadAppCta'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import Image from 'next/image'
import { useState } from 'react'
import Toast from '@components/Toast/toast'
import styles from './UserStatsAndAppLink.module.scss'

interface UserStatsAndAppLinkProps {
  heading: string
  highlightHeading1: string
  highlightHeading2: string
  heroImage: string
  heroImageMobile: string
  inputPlaceholder: string
  scannerUrl: string
  appLink: string
  ctaText: string
}

const UserStatsAndAppLink = ({
  heading,
  highlightHeading1,
  highlightHeading2,
  heroImage,
  heroImageMobile,
  inputPlaceholder,
  scannerUrl,
  appLink,
  ctaText,
}: UserStatsAndAppLinkProps) => {
  const [toastText, setToastText] = useState('')
  const [toastType, setToastType] = useState('')

  return (
    <div id={HomeComponentType.DownloadAppBanner} className={styles.container}>
      <div className={styles.cardContent}>
        <p className={styles.heading}>{heading}</p>
        <div className={styles.NumberFlex}>
          <p className={styles.highlightHeading1}>{highlightHeading1}</p>
          <p className={styles.highlightHeading2}>{highlightHeading2}</p>
        </div>
        <div className={styles.ctaContainer}>
          <DownloadAppCta
            inputPlaceholder={inputPlaceholder}
            imageUrl={scannerUrl}
            widgetType={HomeComponentType.DownloadAppBanner}
            setToastText={setToastText}
            setToastType={setToastType}
          />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <Image objectFit="contain" layout="fill" src={heroImage} alt="card-one" className={styles.hideInMobile} />
        <Image
          objectFit="contain"
          layout="fill"
          src={heroImageMobile}
          alt="card-one"
          className={styles.hideInDesktop}
          loading="lazy"
        />
      </div>
      <div className={styles.ctaContainerMweb}>
        <PrimaryButton
          link={appLink}
          text={ctaText}
          segmentEventName="btn_get_app_link"
          segmentEventProps={{
            ...widgetCommonSegmentProperties({ widgetType: HomeComponentType.DownloadAppBanner }),
            funnel: 'entry',
          }}
        />
      </div>
      <Toast isMobile={false} message={toastText} toastType={toastType} />
    </div>
  )
}

export default UserStatsAndAppLink
