'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import PrimaryButton from '@components/Home/PrimaryButton/Button'
import OverlayModal from '@components/OverlayModal/overlayModal'
import DownloadAppModal from '@components/HomePage/DownloadAppModal/downloadAppModal'
import { ButtonType } from '@components/Home/PrimaryButton/buttonType'
import { HEADER_NAME_SEGMENT, widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import Toast from '@components/Toast/toast'
import { GET_HCAPTCHA_TOKEN_EVENT, LOAD_HCAPTCHA_EVENT } from '@constants/customEvents'
import ScrollEvent from '@components/HomePage/utils/scrollEvent'
import styles from './StackCards.module.scss'

interface ICard {
  id: number
  attributes: {
    heading?: string
    subHeading?: string | null
    ctaText?: string
    type?: string
    description?: string
    appLink: string
    modalHeading?: string
    uspImage: {
      data: {
        attributes: {
          url?: string
        }
      }
    }
    scannerImage: {
      data: {
        attributes: {
          url?: string
        }
      }
    }
  }
}
interface StackCardsProps {
  serviceCardsData: ICard[]
}

const RenderCard = ({
  heading,
  subHeading,
  description,
  uspImageURL,
  scannerImageUrl,
  ctaText,
  appLink,
  type,
  setToastText,
  setToastType,
  modalHeading,
}) => {
  const [show, setShow] = useState(false)
  const [token, setToken] = useState<string>('')

  const onCtaClick = () => {
    setShow(true)
  }
  useEffect(() => {
    if (show) {
      const hcaptchEvent = new CustomEvent(LOAD_HCAPTCHA_EVENT, {
        detail: true,
      })
      document.dispatchEvent(hcaptchEvent)
    }
  }, [show])

  useEffect(() => {
    document.addEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)

    return () => {
      document.removeEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)
    }
  }, [])
  const handleGetHcaptchaToken = (e: CustomEvent) => {
    setToken(e.detail.token)
  }

  return (
    <div className={styles.card}>
      <ScrollEvent
        elementId={type}
        eventProps={{ header_name: HEADER_NAME_SEGMENT[HomeComponentType.StackCard], journey: type }}
        eventName="wrapper_scroll_to_section"
      />
      <div id={type} className={styles.cardContent}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.ctaWeb}>
          <PrimaryButton
            text={ctaText}
            onButtonClick={onCtaClick}
            buttonType={ButtonType.WHITE}
            segmentEventName="btn_get_app_link_initiated"
            segmentEventProps={{
              header_name: HEADER_NAME_SEGMENT[HomeComponentType.StackCard],
              journey: type,
            }}
          />
          <OverlayModal show={show} setShow={setShow}>
            <DownloadAppModal
              imageUrl={scannerImageUrl}
              widgetType={HomeComponentType.StackCard}
              customJourney={type}
              setShowOverlay={setShow}
              setToastText={setToastText}
              setToastType={setToastType}
              token={token}
              setToken={setToken}
              heading={modalHeading}
            />
          </OverlayModal>
        </div>
      </div>
      {uspImageURL && (
        <div className={styles.imageContainer}>
          <Image loading="lazy" objectFit="contain" layout="fill" src={uspImageURL} alt="card-one" />
        </div>
      )}
      <div className={styles.ctaMobile}>
        <PrimaryButton
          text={ctaText}
          link={appLink}
          buttonType={ButtonType.WHITE}
          segmentEventName="btn_get_app_link"
          segmentEventProps={{
            ...widgetCommonSegmentProperties({ widgetType: HomeComponentType.StackCard, customJourney: type }),
            funnel: 'entry',
          }}
        />
      </div>
    </div>
  )
}

const StackedCards = ({ serviceCardsData }: StackCardsProps) => {
  const [toastText, setToastText] = useState('')
  const [toastType, setToastType] = useState('')

  return (
    <>
      <div id={HomeComponentType.StackCard} className={styles.cards}>
        {serviceCardsData.map(cardData =>
          RenderCard({
            heading: cardData.attributes.heading,
            subHeading: cardData.attributes.subHeading,
            description: cardData.attributes.description,
            uspImageURL: cardData.attributes.uspImage.data.attributes.url,
            scannerImageUrl: cardData.attributes.scannerImage.data.attributes.url,
            ctaText: cardData.attributes.ctaText,
            appLink: cardData.attributes.appLink,
            type: cardData.attributes.type,
            modalHeading: cardData.attributes.modalHeading,
            setToastText,
            setToastType,
          })
        )}
      </div>
      <Toast isMobile={false} message={toastText} toastType={toastType} />
    </>
  )
}

export default StackedCards
