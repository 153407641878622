'use client'

import PrimaryButton from '@components/Home/PrimaryButton/Button'
import OverlayModal from '@components/OverlayModal/overlayModal'
import { useEffect, useState } from 'react'
import { HEADER_NAME_SEGMENT, JOURNEY_NAME_SEGMENT } from '@components/HomePage/utils/SegmentEventConstants'
import { GET_HCAPTCHA_TOKEN_EVENT, LOAD_HCAPTCHA_EVENT } from '@constants/customEvents'
import { FALLBACK_MAIN_CTATEXT } from './constants'
import DownloadAppModal from './downloadAppModal'
import { type DownloadAppCtaProps } from './interface'

const DownloadAppCta = ({ mainCtaText, mainCtaType, ...restProps }: DownloadAppCtaProps) => {
  const [show, setShow] = useState(false)
  const [token, setToken] = useState<string>('')
  useEffect(() => {
    if (show) {
      const hcaptchEvent = new CustomEvent(LOAD_HCAPTCHA_EVENT, {
        detail: true,
      })
      document.dispatchEvent(hcaptchEvent)
    }
  }, [show])

  useEffect(() => {
    document.addEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)

    return () => {
      document.removeEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)
    }
  }, [])

  const handleGetHcaptchaToken = (e: CustomEvent) => {
    setToken(e.detail.token)
  }

  const onMainCtaClick = () => {
    setShow(true)
  }
  return (
    <>
      <PrimaryButton
        text={mainCtaText || FALLBACK_MAIN_CTATEXT}
        onButtonClick={onMainCtaClick}
        buttonType={mainCtaType}
        segmentEventName="btn_get_app_link_initiated"
        segmentEventProps={{
          header_name: restProps?.widgetType ? HEADER_NAME_SEGMENT[restProps?.widgetType] : '',
          journey:
            restProps?.customJourney || (restProps?.widgetType ? JOURNEY_NAME_SEGMENT[restProps?.widgetType] : ''),
        }}
      />
      <OverlayModal show={show} setShow={setShow}>
        <DownloadAppModal {...restProps} setShowOverlay={setShow} token={token} setToken={setToken} />
      </OverlayModal>
    </>
  )
}

export default DownloadAppCta
