/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import React, { useState } from 'react'
import { useRouter } from 'next/navigation'

import MobileNumberInput from '@components/Home/PhoneNumberInput/PhoneNumberInput'
import PrimaryButton from '@components/Home/PrimaryButton/Button'
import { INPUT_ERROR_MESSAGE, PHONE_NUMBER_REGEX } from '@components/HomePage/DownloadAppModal/constants'
import { homePageCommonProps } from '@utils/segmentProperties'
import { widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { REWARDS_LOGIN } from '@constants/rewards'
import styles from './couponunlockbanner.module.scss'
import type { IInputProps } from './interfaces'

const InputContainer = ({ inputPlaceholder, ctaText, widgetType, customJourney, isLoggedIn = false }: IInputProps) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValid, setPhoneNumberValid] = useState(true)
  const router = useRouter()

  const handlePhoneNumberChanged = (e: any) => {
    setPhoneNumber(e.target.value)
    setPhoneNumberValid(true)
  }
  const handleInputBlur = () => {
    trackEvent('wrapper_input_phone_entered', {
      ...widgetCommonSegmentProperties({ widgetType, customJourney }),
      ...homePageCommonProps(),
      phone: phoneNumber,
    })
    const isValidPhoneNumber = PHONE_NUMBER_REGEX.test(phoneNumber)
    setPhoneNumberValid(isValidPhoneNumber)
    if (!isValidPhoneNumber) {
      trackEvent('feedback_error_message', {
        ...widgetCommonSegmentProperties({ widgetType, customJourney }),
        ...homePageCommonProps(),
      })
    }
  }
  const handleSubmit = async () => {
    const isPhoneNumberValid = isLoggedIn || PHONE_NUMBER_REGEX.test(phoneNumber)
    setPhoneNumberValid(isPhoneNumberValid)
    if (!isPhoneNumberValid) return
    try {
      await trackEvent('btn_user_signup_initiated', {
        ...homePageCommonProps(),
        ...widgetCommonSegmentProperties({ widgetType, customJourney }),
        funnel: 'entry',
      })
      router.push(REWARDS_LOGIN(!isLoggedIn ? phoneNumber : ''))
    } catch (error) {
      console.log('Failed to enter rewards page', error)
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    handleSubmit()
  }

  return (
    <div className={styles.inputContainer}>
      {!isLoggedIn && (
        <div className={styles.inputWrapper}>
          <form onSubmit={handleFormSubmit}>
            <MobileNumberInput
              onChange={handlePhoneNumberChanged}
              onBlur={handleInputBlur}
              labelText={inputPlaceholder}
              hasError={!phoneNumberValid}
              errMsg={INPUT_ERROR_MESSAGE}
            />
          </form>
        </div>
      )}

      <PrimaryButton text={ctaText} onButtonClick={handleSubmit} />
    </div>
  )
}
export default InputContainer
