'use client'

/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { getTrackerId } from '@utils/utils'
import growthbook from '@components/utils/growthbook'
import { TENANT_TYPE } from '@components/Ackodrive/common/constants'
import { useEnvVariablesClientConfig } from '@context/EnvClientContext/EnvVariablesClientContext'
import HeaderDesktopAndMweb from './headerDesktopAndMweb'

const HeaderDesktopAndMwebGrowthbook = ({
  isSemPage,
  headerLinks,
  darkDesktop,
  darkMobile,
  isHomeWrapper,
  theme,
  loginBaseUrl = '',
}) => {
  const config = useEnvVariablesClientConfig()
  const [growthBook, setGrowthBook] = useState<any>()
  useEffect(() => {
    if (config) {
      const growthBook = growthbook(config?.ACKO_ENV)
      growthBook.loadFeatures()
      growthBook.setAttributes({ deviceId: getTrackerId(TENANT_TYPE.HOMEPAGE) })
      setGrowthBook(growthBook)
    }
  }, [config])
  return (
    <GrowthBookProvider growthbook={growthBook}>
      <HeaderDesktopAndMweb
        isSemPage={isSemPage}
        headerLinks={headerLinks}
        darkDesktop={darkDesktop}
        darkMobile={darkMobile}
        isHomeWrapper={isHomeWrapper}
        theme={theme}
        loginBaseUrl={loginBaseUrl}
      />
    </GrowthBookProvider>
  )
}

export default HeaderDesktopAndMwebGrowthbook
