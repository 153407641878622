import { HomeComponentType } from './HomeComponentType'

export const HEADER_NAME_SEGMENT = {
  [HomeComponentType.RatingWidget]: 'app_download_generic',
  [HomeComponentType.StackCard]: 'app_download_specific_feature',
  [HomeComponentType.CouponUnlockBanner]: 'unlock_coupons',
  [HomeComponentType.LandingWidget]: '',
  [HomeComponentType.ProductBanner]: 'acko_drive',
  [HomeComponentType.DownloadAppBanner]: 'app_download_generic_footer',
}

export const JOURNEY_NAME_SEGMENT = {
  [HomeComponentType.RatingWidget]: 'app_download_generic',
  [HomeComponentType.CouponUnlockBanner]: 'unlock_coupons',
  [HomeComponentType.LandingWidget]: '',
  [HomeComponentType.ProductBanner]: 'explore_new_cars',
  [HomeComponentType.DownloadAppBanner]: 'app_download_generic_footer',
}

export const widgetCommonSegmentProperties = ({
  widgetType,
  customJourney,
}: {
  widgetType?: HomeComponentType
  customJourney?: string
}) => ({
  funnel: 'visit',
  header_name: widgetType ? HEADER_NAME_SEGMENT[widgetType] : '',
  journey: customJourney || (widgetType ? JOURNEY_NAME_SEGMENT[widgetType] : ''),
})
