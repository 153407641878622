'use client'

import { pageEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { homePageCommonProps } from '@utils/segmentProperties'
/* eslint-disable consistent-return */
/* eslint-disable n/no-callback-literal */

import { useEffect } from 'react'

const OPTIONS = {
  threshold: 0,
}

const useObserver = (elementId, cb) => {
  useEffect(() => {
    const element = document.getElementById(elementId)

    if (element) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            cb()
            observer.unobserve(entry.target)
          }
        })
      }, OPTIONS)

      observer.observe(element)

      return () => {
        observer.disconnect()
      }
    }
  }, [])
}

const ScrollEvent = ({ elementId, eventName, eventProps = {} }) => {
  useObserver(elementId, async () => {
    await pageEvent(eventName, { ...homePageCommonProps(), ...eventProps })
  })

  return null
}

export default ScrollEvent
