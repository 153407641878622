/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import Image from 'next/image'
import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { r2d2 } from '@utils/utils'
import { homePageCommonProps } from '@utils/segmentProperties'
import { homePageFunnel } from '@configs/segmentEvents.config'
import { getUrlWithQueryParams } from '@utils/queryParamUtils'
import styles from './ProductCardButton.module.scss'

const Arrow = (
  <svg
    className={styles.arrowStyle}
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.816389 0.598144C1.05023 0.346767 1.44357 0.33255 1.69495 0.566389L4.88105 3.53021C5.00747 3.64781 5.07929 3.8127 5.07929 3.98536C5.07929 4.15803 5.00747 4.32292 4.88105 4.44052L1.69495 7.40434C1.44357 7.63818 1.05023 7.62396 0.816389 7.37258C0.58255 7.1212 0.596767 6.72786 0.848144 6.49402L3.54495 3.98536L0.848144 1.4767C0.596767 1.24287 0.58255 0.84952 0.816389 0.598144Z"
      fill="white"
    />
  </svg>
)

export const ProductCardButton = ({ product, featured, index = 0, currentIndex }) => (
  <a
    onClick={e => {
      e.preventDefault()
      ;(async function IFFE() {
        try {
          const trackingEvents = [
            trackEvent('btn_explore_insurance', {
              ...homePageCommonProps(),
              funnel: homePageFunnel[product.shortName?.toLowerCase()],
              type: product.shortName?.toLowerCase(),
              experiment_name: 'wrapper_v3',
            }),
            r2d2(
              { ekind: 'btn_explore_insurance', odata: { product: product.shortName?.toLowerCase() } },
              `${window.location.origin}/api/r2d2/`
            ),
          ]
          await Promise.allSettled(trackingEvents)
        } catch (e) {
          console.log('track event error')
        }
        window.location.href = getUrlWithQueryParams(product.url)
      })()
    }}
    className={`${styles.productCardButton} ${featured ? styles.featured : ''} ${
      currentIndex === index && styles.activeCard
    }`}
    href={product.url}>
    <Image width={100} height={100} className={styles.image} alt={product.name} src={product.iconImage} />
    <span className={styles.cta}>
      {product.name} {Arrow}
    </span>
    <div className={styles.active} />
  </a>
)
