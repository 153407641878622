'use client'

import PrimaryButton from '@components/Home/PrimaryButton/Button'
import Image from 'next/image'
import { useState } from 'react'
import Toast from '@components/Toast/toast'
import DownloadAppCta from '@components/HomePage/DownloadAppModal/downloadAppCta'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import { BTN_GET_APP_LINK } from '@constants/homeSegmentEvent'
import { APP_STORE_RATING_URL, PLAY_STORE_RATING_URL, RATING_STAR_URL, RATING_STAR_URL_80 } from './s3constants'
import styles from './ratingWidget.module.scss'
import type RatingWidgetProps from './interface'
import { APP_STORE_ALT_TEXT, PLAY_STORE_ALT_TEXT, RATING_STAR_80_ALT_TEXT, RATING_STAR_ALT_TEXT } from './constants'

export const RatingWidget = ({
  preHeading,
  heading,
  subheading,
  inputPlaceholder,
  ctaText,
  appLink,
  scannerUrl,
}: RatingWidgetProps) => {
  const [toastText, setToastText] = useState('')
  const [toastType, setToastType] = useState('')

  return (
    <div id={HomeComponentType.RatingWidget} className={styles.rootContainer}>
      <div className={styles.infoContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }}/>
          <div className={styles.preHeading} dangerouslySetInnerHTML={{ __html: preHeading }} />
          <div className={styles.subheading}>{subheading}</div>
        </div>
        <div className={styles.ctaContainer}>
          <DownloadAppCta
            inputPlaceholder={inputPlaceholder}
            imageUrl={scannerUrl}
            widgetType={HomeComponentType.RatingWidget}
            setToastText={setToastText}
            setToastType={setToastType}
          />
        </div>
      </div>
      <div className={styles.ratingContainer}>
        <div className={styles.starContainer}>
          <div className={styles.ratingStar}>
            <Image src={RATING_STAR_URL} alt={RATING_STAR_ALT_TEXT} fill />
          </div>
          <div className={styles.ratingStar}>
            <Image src={RATING_STAR_URL} alt={RATING_STAR_ALT_TEXT} fill />
          </div>
          <div className={styles.ratingStar}>
            <Image src={RATING_STAR_URL} alt={RATING_STAR_ALT_TEXT} fill />
          </div>
          <div className={styles.ratingStar}>
            <Image src={RATING_STAR_URL} alt={RATING_STAR_ALT_TEXT} fill />
          </div>
          <div className={styles.ratingStar}>
            <Image src={RATING_STAR_URL_80} alt={RATING_STAR_80_ALT_TEXT} fill />
          </div>
        </div>
        <div className={styles.ratings}>
          <div className={styles.storeRating}>
            <Image src={APP_STORE_RATING_URL} alt={APP_STORE_ALT_TEXT} fill />
          </div>
          <div className={styles.storeRating}>
            <Image src={PLAY_STORE_RATING_URL} alt={PLAY_STORE_ALT_TEXT} fill />
          </div>
        </div>
      </div>
      <div className={styles.ctaContainerMweb}>
        <PrimaryButton
          link={appLink}
          text={ctaText}
          segmentEventName={BTN_GET_APP_LINK}
          segmentEventProps={{
            ...widgetCommonSegmentProperties({ widgetType: HomeComponentType.RatingWidget }),
            funnel: 'entry',
          }}
        />
      </div>
      <Toast isMobile={false} message={toastText} toastType={toastType} />
    </div>
  )
}
