/* eslint-disable @typescript-eslint/no-floating-promises */

import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import MobileNumberInput from '@components/Home/PhoneNumberInput/PhoneNumberInput'
import PrimaryButton from '@components/Home/PrimaryButton/Button'
import { homePageCommonProps } from '@utils/segmentProperties'
import Image from 'next/image'
import { useState } from 'react'
import { ERROR, ERROR_MESSAGE, SUCCESS, SUCCESS_MESSAGE } from '@constants/toast'
import { widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import { doToast } from '@components/utils/toast'
import {
  FALLBACK_CTA_TEXT,
  FALLBACK_HEADING,
  FALLBACK_PLACEHOLDER,
  FALLBACK_SUBHEADING,
  INPUT_ERROR_MESSAGE,
  PHONE_NUMBER_REGEX,
  SCANNER_IMAGE_ALT,
  SEND_APPLINK_WHATSAPP_ENDPOINT,
} from './constants'
import styles from './downloadAppModal.module.scss'
import { type HCaptchaProps, type DownloadAppModalProps } from './interface'

const DownloadAppModal = ({
  heading,
  subHeading,
  imageUrl,
  inputPlaceholder,
  ctaText,
  widgetType,
  customJourney,
  setShowOverlay,
  setToastText,
  setToastType,
  token,
  setToken,
}: DownloadAppModalProps & HCaptchaProps) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValid, setPhoneNumberValid] = useState(true)

  // hcaptcha states

  const handlePhoneNumberChanged = (e: any) => {
    setPhoneNumber(e.target.value)
    setPhoneNumberValid(true)
  }
  const handleInputBlur = () => {
    trackEvent('wrapper_input_phone_entered', {
      ...homePageCommonProps(),
      ...widgetCommonSegmentProperties({ widgetType, customJourney }),
      phone: phoneNumber,
    })
    const isValidPhoneNumber = PHONE_NUMBER_REGEX.test(phoneNumber)
    setPhoneNumberValid(isValidPhoneNumber)
    if (!isValidPhoneNumber) {
      trackEvent('feedback_error_message', {
        ...homePageCommonProps(),
        ...widgetCommonSegmentProperties({ widgetType, customJourney }),
      })
    }
  }

  const onCtaClick = async () => {
    const isPhoneNumberValid = PHONE_NUMBER_REGEX.test(phoneNumber)
    setPhoneNumberValid(isPhoneNumberValid)
    if (!isPhoneNumberValid) return

    try {
      const isValidPhoneNumber = PHONE_NUMBER_REGEX.test(phoneNumber)
      if (!isValidPhoneNumber) {
        setPhoneNumberValid(isValidPhoneNumber)
        return
      }
      const response = await fetch(SEND_APPLINK_WHATSAPP_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-captcha-token': token,
        },
        body: JSON.stringify({ phone: phoneNumber, componentType: customJourney || widgetType }),
      })
      if (response.ok) {
        trackEvent('btn_get_app_link', {
          ...homePageCommonProps(),
          ...widgetCommonSegmentProperties({ widgetType, customJourney }),
          funnel: 'entry',
        })
        setToken('')
        doToast({ message: SUCCESS_MESSAGE, type: SUCCESS, setToastText, setToastType })
        if (setShowOverlay) {
          setShowOverlay(false)
        }
      } else {
        console.error('Whatsapp communication failed')
        doToast({ message: ERROR_MESSAGE, type: ERROR, setToastText, setToastType })
        if (setShowOverlay) {
          setShowOverlay(false)
        }
      }
    } catch (err) {
      doToast({ message: ERROR_MESSAGE, type: ERROR, setToastText, setToastType })
      console.error('Whatsapp communication api call error', err)
      if (setShowOverlay) {
        setShowOverlay(false)
      }
    }
  }

  const onFormSubmit = event => {
    event.preventDefault()
    onCtaClick()
  }
  return (
    <div className={styles.downloadAppRootContainer}>
      <div className={styles.headerSection}>
        <div className={styles.heading}>{heading || FALLBACK_HEADING}</div>
        <div className={styles.subHeading}>{subHeading || FALLBACK_SUBHEADING}</div>
      </div>
      {imageUrl ? (
        <div className={styles.scannerSection}>
          <Image src={imageUrl} alt={SCANNER_IMAGE_ALT} layout="fill" priority />
        </div>
      ) : null}
      <div className={styles.inputContainer}>
        <form onSubmit={onFormSubmit}>
          <MobileNumberInput
            labelText={inputPlaceholder || FALLBACK_PLACEHOLDER}
            hasError={!phoneNumberValid}
            onChange={handlePhoneNumberChanged}
            onBlur={handleInputBlur}
            errMsg={INPUT_ERROR_MESSAGE}
          />
        </form>
        <PrimaryButton onButtonClick={onCtaClick} text={ctaText || FALLBACK_CTA_TEXT} disabled={!token} />
      </div>
    </div>
  )
}

export default DownloadAppModal
