import Image from 'next/image'
import { TOOLTIP_TIP_SRC } from '@constants/toolTip'
import styles from './producttooltip.module.scss'

const ProductTooltip = ({ toolTipText }) => (
    <div className={styles.tooltipWrapper}>
      <div className={styles.edgeWrapper}>
        <Image alt="" className={styles.edges} src={TOOLTIP_TIP_SRC} width={27.2} height={18} priority/>
      </div>
      <span className={styles.tooltipText} dangerouslySetInnerHTML={{ __html: toolTipText }} />
    </div>
  )

export default ProductTooltip
