import React from 'react'
import styles from './phoneInput.module.scss'

interface MobileNumberInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  labelText: string
  hasError?: boolean
  errMsg?: string
}

const MobileNumberInput: React.FC<MobileNumberInputProps> = ({
  onChange,
  onBlur,
  labelText,
  hasError = false,
  errMsg,
}) => (
  <div className={`${styles.mobileNumberInput} ${hasError ? styles.error : ''}`}>
    <div className={styles.inputWrapper}>
      <div className={styles.countryCode}>+91</div>
      <input type="number" placeholder={labelText} onChange={onChange} onBlur={onBlur} autoComplete="off" />
    </div>
    {hasError && errMsg && <span className={styles.errorMessage}>{errMsg}</span>}
  </div>
)

export default MobileNumberInput
