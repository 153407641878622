export const CAROUSEL_SCROLL_TIME = 3000
export const FEATURED_CARD_OFFSET = 2
export const PRODUCTS_LENGTH = 5
export const HOME_PAGE_ROUTE = '/t/car'

export const VARIANT = {
  WITHOUT_PRODUCT_CARDS: 'WITHOUTCARDS',
  WITH_PRODUCT_CARDS: 'WITHCARDS',
}

export const AutCarouselComponent = 'AutoCarousel'

export const customCarouselProps = {
  autoPlay: true,
  centerMode: true,
  showArrows: false,
  centerSlidePercentage: 100,
  infiniteLoop: true,
  interval: CAROUSEL_SCROLL_TIME,
  showStatus: false,
  showIndicators: false,
}
